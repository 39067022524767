import {
  RapidStorLocation,
  RapidStorUnitSortOrder,
} from '../services/rapidstor';

export default interface Location {
  name: string;
  locationId: string;
  locationCode: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  websiteUrl: string;
  latitude: number;
  longitude: number;
  timezoneOffset: number;
  address: {
    address1: string;
    address2: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
  };
  deleted: boolean;
  archived: boolean;
  rapidstorEnabled: boolean;
  token: string;
  createdAt: string;
  updatedAt: string;
  settings: {
    logo: string;
    groundFloor: number;
    groupUnitsByFloor: boolean;
    unitSortOrder: RapidStorUnitSortOrder[];
    billingDefault: string;
    sizeSymbol: string;
    areaSizeSymbol: string;
    allowMoveIn: boolean;
    showAreaInsteadOfDimensions: boolean;
  };
  rapidstor: {
    allowMoveIn: boolean;
    completeMoveinUrl: string;
    includeReservedUnitsInOccupied: boolean;
    reservationNumberOfDaysInAdvanceLimit: number;
  };
  hours: {
    officeMondayOpen: string;
    officeTuesdayOpen: string;
    officeWednesdayOpen: string;
    officeThursdayOpen: string;
    officeFridayOpen: string;
    officeSaturdayOpen: string;
    officeSundayOpen: string;
    officeMondayClose: string;
    officeTuesdayClose: string;
    officeWednesdayClose: string;
    officeThursdayClose: string;
    officeFridayClose: string;
    officeSaturdayClose: string;
    officeSundayClose: string;
  };
}

export const formatAddress = (location: Location): string => {
  const { address1, address2, city, region, postalCode } = location.address;

  return [
    address1 ? `${address1}, ` : '',
    address2 ? `${address2}, ` : '',
    city ? `${city}, ` : '',
    region ? `${region}, ` : '',
    postalCode ? postalCode : '',
  ].join('');
};

export const locationTransformer = (location: RapidStorLocation) => {
  return <Location>{
    // This is not nice but it's all we can do really
    name: location.sSiteName
      .replace('Cinch Self Storage - ', '')
      .replace('Cinch Self Storage ', '')
      .replace('Cinch Storage - ', ''),
    locationId: location.SiteID,
    locationCode: location.sLocationCode,
    contactName: location.sContactName,
    contactEmail: location.sEmailAddress,
    contactPhone: location.sSitePhone,
    websiteUrl: location.sWebSiteURL,
    timezoneOffset: location.iGMTTimeOffset,
    latitude: parseFloat(location.dcLatitude),
    longitude: parseFloat(location.dcLongitude),
    address: {
      address1: location.sSiteAddr1,
      address2: location.sSiteAddr2,
      city: location.sSiteCity,
      region: location.sSiteRegion,
      postalCode: location.sSitePostalCode,
      country: location.sSiteCountry,
    },
    rapidstor: {
      allowMoveIn: location.rapidstor.allowMoveIn,
      completeMoveinUrl: location.rapidstor.completeMoveinUrl,
      reservationNumberOfDaysInAdvanceLimit: location.rapidstor.reservationNumberOfDaysInAdvanceLimit,
    },
    deleted: location.deleted,
    archived: location.bArchived == 'true',
    rapidstorEnabled: location.rapidstorEnabled,
    token: location.token,
    createdAt: location.dCreated,
    updatedAt: location.dUpdated,
    settings: {
      logo: location.rapidstor.siteLogo,
      groundFloor: Number.parseInt(location.rapidstor.groundFloor) || 0,
      groupUnitsByFloor: location.rapidstor.unitListing.useMultilevel,
      billingDefault: location.rapidstor.billingDefault,
      sizeSymbol: location.rapidstor.sizeSymbol,
      areaSizeSymbol: location.rapidstor.areaSizeSymbol,
      allowMoveIn: location.rapidstor.allowMoveIn,
      showAreaInsteadOfDimensions:
        location.rapidstor.showAreaInsteadOfDimensions,
      unitSortOrder: [
        location.rapidstor.unitListing.sortOrder.primary,
        location.rapidstor.unitListing.sortOrder.secondary,
        location.rapidstor.unitListing.sortOrder.tertiary,
      ].filter((sortOrder) => sortOrder !== 'none'),
    },
    hours: location.hours,
  };
};
